<template>
  <section class="section dark-bg solutions-section">
    <span id="solutions" class="solutions__hash hash-elem"></span>
    <div class="container">
      <div class="solutions">
        <h2 class="title title--center title--invert solutions__title">Наши решения</h2>
        <ul class="solutions__list">
          <li class="solutions__item solutions__item--wide" data-aos="fade-up" data-aos-duration="800" data-aos-delay="0">
            <div class="solution solution--wide">
              <h3 class="solution__title">{{ erpData.title }}</h3>
              <div class="solution__text">
                <p>{{ erpData.textTop }}</p>
                <p>{{ erpData.textBottom }}</p>
              </div>
              <div class="solution__links">
                <LinkBtn to="/erp" title="ERP Логистика">ERP Логистика</LinkBtn>
                <LinkBtn to="/manufacturing" title="ERP Производство">ERP Производство</LinkBtn>
              </div>
            </div>
          </li>
          <li
            v-for="(item, index) in solutionsList"
            :key="`solution-${index}`"
            class="solutions__item"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="0"
          >
            <div class="solution">
              <h3 class="solution__title">{{ item.title }}</h3>
              <p class="solution__text">{{ item.text }}</p>
              <div class="solution__icon">
                <component :is="item.icon" />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import Crm from '~/components/icons/index/crm.vue';
import Cart from '~/components/icons/index/cart.vue';
import Rocket from '~/components/icons/index/rocket.vue';
import Code from '~/components/icons/index/code.vue';
import LinkBtn from '~/components/buttons/link-btn.vue';

const erpData = {
  title: 'ERP-системы',
  textTop:
    'Анализируем бизнес-процессы и создаем единую платформу, которая объединяет все подразделения компании для эффективного управления финансами, производственными процессами, цепочками поставок и кадрами.',
  textBottom: 'Специализируемся на ERP-системах для произодственных и логистических предприятий',
};
const solutionsList = [
  {
    title: 'CRM-системы',
    text: 'Проектируем и внедряем CRM-системы, которые помогают управлять продажами, отслеживать все этапы сделки и хранить информацию о клиентах в одном месте. Наши решения включают инструменты для аналитики, отчетности, автоматизации продаж и коммуникации с клиентами.',
    icon: Crm,
  },
  {
    title: 'E-commerce',
    text: 'Создаем инструменты для автоматизации продаж в интернет-магазинах и на маркетплейсах. Наши боты помогают улучшить клиентский опыт, ускорить процессы и повысить эффективность бизнеса.',
    icon: Cart,
  },
  {
    title: 'ИИ-технологии',
    text: 'Разрабатываем и интегрируем решения на базе нейросетей для автоматизации задач и повышения эффективности бизнес-процессов.',
    icon: Rocket,
  },
  {
    title: 'Мобильные приложения',
    text: 'Разрабатываем мобильные приложения для IOS и Android. Осуществляем техническую поддержку после публикации в магазинах приложений.',
    icon: Code,
  },
];
</script>

<style scoped lang="scss">
@use '~/assets/styles/mixins';

.solutions {
  color: var(--white);
  position: relative;
  z-index: 2;
  &-section {
    position: relative;
    z-index: 3;
    &::before,
    &::after {
      content: '';
      display: block;
      @include mixins.radial-gradient;
      position: absolute;
      z-index: 1;
    }

    &::before {
      left: -230px;
      top: 400px;
      width: 1130px;
      height: 1130px;
    }

    &::after {
      bottom: -135px;
      right: -370px;
      width: 960px;
      height: 960px;
    }
  }

  &__title {
    margin-bottom: 63px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 73px;
    row-gap: 50px;
  }

  &__item {
    &--wide {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

.solution {
  display: flex;
  flex-direction: column;
  gap: 45px;
  height: 100%;
  padding: 72px 45px 60px 94px;
  box-sizing: border-box;
  @include mixins.gradient-border;

  &--wide {
    position: relative;
    overflow: hidden;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: image-set(url('/img/index/laptop.webp') type('image/webp'), url('/img/index/laptop.png') type('image/png'));
      background-repeat: no-repeat;
      background-size: 50%, contain;
      background-position: bottom right;
      z-index: 1;
    }

    .solution__text {
      max-width: 60%;
    }
  }

  &__links {
    display: flex;
    gap: 45px;
    position: relative;
    z-index: 2;
  }

  &__title {
    font-size: 60px;
    line-height: 72px;
    font-weight: 600;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: var(--standard-line);
    padding-right: 45px;
    position: relative;
    z-index: 2;
  }

  &__icon {
    margin-top: auto;
    align-self: flex-end;
  }
}

@media (max-width: 1400px) {
  .solution {
    padding: 40px;

    &__title {
      font-size: var(--title-text);
      line-height: var(--title-line);
    }
  }
}

@media (max-width: 1200px) {
  .solution {
    padding: 40px;

    &__text {
      padding: 0;
    }
  }

  .solutions {
    &__list {
      row-gap: 40px;
      column-gap: 40px;
    }

    &-section {
      &::before,
      &::after {
        width: 600px;
        height: 600px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .solution {
    gap: 36px;

    &--wide {
      &::before {
        background-size: contain;
        opacity: 0.3;
      }

      .solution {
        &__text {
          max-width: 100%;
        }
      }
    }
  }

  .solutions {
    &__title {
      margin-bottom: 36px;
    }
  }
}

@media (max-width: 767px) {
  .solutions {
    &__list {
      grid-template-columns: repeat(1, 1fr);
    }

    &__item {
      &--wide {
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }
  }

  .solution {
    &__links {
      gap: 20px;
      flex-wrap: wrap;
    }

    &__icon {
      width: 50px;
      height: 50px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 500px) {
  .solution {
    padding: 20px;
    gap: 20px;
  }
}
</style>
