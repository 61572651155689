<template>
  <section class="section dark-bg steps-section" ref="steps-ref">
    <div class="container">
      <div class="steps">
        <h2 class="title title--center title--invert steps__title">Этапы сотрудничества</h2>
        <ul class="steps__list">
          <li class="steps__item" v-for="(item, index) in steps" :key="item.index">
            <div :class="`step ${state.isVisible ? 'step--animated' : ''}`">
              <span class="step__index" data-aos="fade-left" data-aos-duration="800" :data-aos-delay="300 * index">{{ item.index }}/</span>
              <h3 class="step__title">{{ item.title }}</h3>
              <p class="step__text text">{{ item.text }}</p>
            </div>
          </li>
          <li :class="`steps__item steps__item--img ${state.isVisible ? 'animated' : ''}`"></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
interface IState {
  isVisible: boolean;
}
const state = reactive<IState>({
  isVisible: false,
});

const stepsSection = useTemplateRef('steps-ref');

onMounted(() => {
  document.addEventListener('scroll', scrollEventHandler);
});

onBeforeUnmount(() => {
  deleteScrollEvt();
});

const scrollEventHandler = () => {
  const { y: topOffset } = stepsSection.value.getBoundingClientRect();
  if (topOffset <= 0) {
    deleteScrollEvt();
    state.isVisible = true;
  }
};

const deleteScrollEvt = () => {
  document.removeEventListener('scroll', scrollEventHandler);
};

const steps = [
  {
    index: '01',
    title: 'Исследование',
    text: 'Начинаем с постановки целей и анализа бизнес-процессов компании. Общаемся с ключевыми участниками, чтобы понять потребности, собираем данные и готовим документацию.',
  },
  {
    index: '02',
    title: 'Концепция',
    text: 'На основе анализа разрабатываем концепцию решения и системный функционал. После согласуем техническое задание и рассчитаем стоимость проекта.',
  },
  {
    index: '03',
    title: 'Разработка',
    text: 'Разрабатываем систему, настраиваем функционал, интегрируем, переносим данные и тестируем.',
  },
  {
    index: '04',
    title: 'Запуск',
    text: 'Проводим обучение сотрудников, передаем документацию и запускаем готовое решение в работу.',
  },
  {
    index: '05',
    title: 'Поддержка',
    text: 'Осуществляем техническую поддержку столько времени, сколько  потребуется, или передаем все необходимые доступы вашим IT-специалистам.',
  },
];
</script>

<style scoped lang="scss">
@use '~/assets/styles/mixins';

.steps {
  color: var(--white);

  &-section {
    position: relative;
    z-index: 1;

    &::before {
      @include mixins.pseudo-elem;
      @include mixins.radial-gradient;
      width: 1055px;
      height: 1055px;
      right: -500px;
      bottom: -190px;
    }
  }

  &__title {
    margin-bottom: 132px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 115px;
    row-gap: 96px;
  }

  &__item {
    &--img {
      position: relative;
      z-index: 10;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 280px;
        position: absolute;
        top: 184px;
        transform: translate(-1900px, -50%);
        background-image: image-set(url('/img/index/rocket.webp') type('image/webp'), url('/img/index/rocket.png') type('image/png'));
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;
      }
    }
  }

  .animated {
    &::before {
      animation: rocketFly 2s ease 1 forwards;
    }
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  &::before {
    @include mixins.pseudo-elem;
    height: 2px;
    width: 960px;
    top: 184px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--accent-color);
    opacity: 0;
  }

  &--animated {
    &::before {
      animation: fadeIn 1s ease 2s 1 forwards;
    }
  }

  &::after {
    @include mixins.pseudo-elem;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--accent-color);
    top: 184px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__index {
    font-size: 100px;
    font-weight: 900;
    line-height: 121px;
    color: var(--accent-color);
    margin-bottom: 126px;
  }

  &__title {
    font-size: 35px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 36px;
  }
}

@media (max-width: 1400px) {
  .step {
    &__title {
      font-size: 30px;
      line-height: 1;
    }
  }
}

@media (max-width: 1200px) {
  .step {
    &::before {
      width: 600px;
    }
  }
}

@media (max-width: 1024px) {
  .steps {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .step {
    &::before {
      width: 768px;
    }
  }
}

@media (max-width: 767px) {
  .steps {
    &__title {
      margin-bottom: 36px;
    }
    &__list {
      column-gap: 50px;
      row-gap: 50px;
    }
  }
}

@media (max-width: 600px) {
  .steps {
    &__list {
      grid-template-columns: repeat(1, 1fr);
    }
    &__item {
      &--img {
        display: none;
      }
    }
  }

  .step {
    &::before,
    &::after {
      top: 154px;
      opacity: 1;
    }
    &--animated {
      &::before {
        animation: none;
      }
    }

    &__index {
      font-size: 70px;
      height: 90px;
    }
  }
}
@keyframes rocketFly {
  from {
    transform: translate(-1900px, -50%);
  }

  to {
    transform: translate(0, -50%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
